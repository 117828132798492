<template>
    <div class="home">
      <Header id="2"></Header>
      <div class="pc-teacher">
        <img class="pc-banner" src="@/assets/img/teachers/banner2.png" alt=""/>
        <template v-for="item in teachers2">
        <h1 :key="item.area" class="pc-teacher-fl">{{ item.areaname }}</h1>
        <ul :key="'teahcers_'+item.area" v-if="item.teahcers && item.teahcers.length>0"   class="pc-teacher-list">
          <li :key="item2.id" v-for="item2 in item.teahcers" class="pc-teacher-list-item">
            <a @click="toDetails(item2.id)" href="javascript:;">
              <div class="prefix">
                <img :src="item2.photocdnurl" alt="">
              </div>
              <div class="suffix">
                <p class="suffix-name">{{item2.name}}</p>
                <p class="suffix-describe">{{item2.description}}</p>
              </div>
            </a>
          </li>
          </ul>
        </template>
          <!-- <h1 class="pc-teacher-fl">心理学专家</h1>
        <ul  class="pc-teacher-list">
          <li :key="index" v-for="item,index in teachers.xlx" class="pc-teacher-list-item">
            <a @click="toDetails(item.id)" href="javascript:;">
              <div class="prefix">
                <img :src="item.photo" alt="">
              </div>
              <div class="suffix">
                <p class="suffix-name">{{item.name}}</p>
                <span>{{item.tx}}</span>
                <span>&nbsp;</span>
                <p class="suffix-describe">{{item.jj}}</p>
              </div>
            </a>
          </li>
          </ul> -->
      </div>
      <Footer></Footer>
    </div>
  </template>
  <script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AllTeachers from "@/assets/js/teachers.js";
export default {
  name: "HomeView",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      teachers: AllTeachers,
      teachers2: []
    };
  },
  methods: {
    toDetails(id) {
      let routeUrl = this.$router.resolve({
        path: "/teacher/details",
        query: { id }
      });
      window.open(routeUrl.href, "_blank");
    }
  },
  created(){
    this.$utils.request("getTeacherList",{},res=>{
      if(res && res.length>0){
        // console.log('teachers2')
        this.teachers2 = res;
      }
    })
  }
};
</script>
  <style lang="less">
.pc-teacher {
  background-color: #f7f8fa;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
}
.pc-banner {
  width: 1208px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.pc-teacher-list {
  width: 1240px;
  display: flex;
  flex-wrap: wrap;
  //   margin: -16px;
}
.pc-teacher-list-item {
  width: 33.33%;
  padding: 16px;
  box-sizing: border-box;
}
.pc-teacher-list-item a {
  width: 100%;
  height: 157px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 34px;
  border-radius: 8px;
  background-color: #fff;
}
.pc-teacher-list-item a .prefix {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
}
.pc-teacher-list-item a .prefix img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.pc-teacher-list-item a .suffix {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 29px;
}
.pc-teacher-list-item a .suffix-name {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 4px;
}
.pc-teacher-list-item a .suffix span {
  display: inline-block;
  font-size: 14px;
  color: #8b8a8a;
  margin: 18px 0;
}
.pc-teacher-list-item a .suffix-describe {
  font-size: 14px;
  color: #000;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  overflow: hidden;
  line-height: 18px;
}
.pc-teacher-fl {
  font-size: 22px;
  font-weight: 800;
  margin-top: 16px;
  color: #2770af;
}
</style>
  
  