<template>
  <div class="header">
    <div class="header-content">  
      <img src="@/assets/img/logo.png" alt="" class="logo">
      <ul class="tabs">
        <li class="tabs-item" :class="id=='1'?'active':''"><a href="javascript:;" @click="toPath('/')">首页</a></li>
        <li class="tabs-item" :class="id=='2'?'active':''"><a href="javascript:;" @click="toPath('/teachers')">师资力量</a></li>
        <li class="tabs-item"><a target="_blank" href="https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/index.html">村居云课堂</a>
      </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props:{
    id:String
  },
  data(){
    return {

    }
  },

  created(){
    // console.log(this.id)
  },
  methods:{
    toPath(path){
      this.$router.push({path})
    }
  }
}
</script>
<style lang="less">
.header {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  &-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    .logo {
      width: 122px;
      height: 45px;
    }
    .tabs {
      display: flex;
      align-items: center;
      &-item {
        margin-left: 40px;
        font-size: 18px;
        line-height: 1;
        color: #7f8296;
        cursor: pointer;
        &.active {
          position: relative;
          a {
            color: #000;
          }
          &::after {
            display: block;
            content: " ";
            position: absolute;
            bottom: -13px;
            left: 50%;
            transform: translate(-50%);
            width: 16px;
            height: 3px;
            border-radius: 1.5px;
            background-color: #26973e;
          }
        }
      }
    }
  }
}
</style>

