import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Teachers from '../views/Teachers.vue'
import TeacherDetails from '../views/TeacherDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/teachers',
    component: Teachers
  },
  {
    path: '/teacher/details',
    component: TeacherDetails
  }
]

const router = new VueRouter({
  routes
})

export default router
