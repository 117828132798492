<template>
  <div class="swiper">
    <h2 class="swiper-header">核心要素</h2>
    <div class="swiper-content">
      <div class="swiper-viewport">
        <ul class="swiper-list" :style="{transform: 'translate(' + idx * -1270 + 'px)'}">
          <li class="swiper-list-item" v-for="(item,index) in swiper" :key="index">
            <img :src="item.url" alt="">
          </li>
        </ul>
      </div>
      <div class="prev" :class="{active: idx == 0 ? '' : 'active'}" @click="onPrev"></div>
      <div class="next" :class="{active: idx == swiper.length - 1 ? '' : 'active'}" @click="onNext"></div>
      <ul class="pedometer">
        <li class="pedometer-item" :class="{active: index == idx}" v-for="(item,index) in swiper" :key="index" @click="changeSwiper(index)"></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Swiper",
  data() {
    return {
      idx: 0,
      swiper: [
        {
          url: require("@/assets/img/swiper1.png")
        },
        {
          url: require("@/assets/img/swiper2.png")
        },
        {
          url: require("@/assets/img/swiper3.png")
        },
        {
          url: require("@/assets/img/swiper4.png")
        },
        {
          url: require("@/assets/img/swiper5.png")
        },
      ]
    }
  },
  methods: {
    onNext() {
      if(this.idx == this.swiper.length - 1) return;
      this.idx++
    },
    onPrev() {
      if(this.idx == 0) return;
      this.idx--
    },
    changeSwiper(index) {
      this.idx = index;
      console.log(this.idx)
    }
  }
};
</script>
<style lang="less" scoped>
.swiper {
  margin-bottom: 125px;
  &-header {
    font-size: 36px;
    color: #000;
    font-weight: 600;
    margin-top: 137px;
    margin-bottom: 27px;
    position: relative;
    text-align: center;
    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translate(-50%);
      width: 32px;
      height: 4px;
      border-radius: 2px;
      background-color: #26973E;
    }
  }
  &-content {
    width: 1270px;
    position: relative;
    border-radius: 36px;
    .prev, .next {
      width: 26px;
      height: 46px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .prev {
      left: -106px;
      background: url(@/assets/img/prev.png) no-repeat;
      background-size: 100% 100%;
      &.active {
        background: url(@/assets/img/prev-a.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .next {
      right: -106px;
      background: url(@/assets/img/next.png) no-repeat;
      background-size: 100% 100%;
      &.active {
        background: url(@/assets/img/next-a.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .pedometer {
      position: absolute;
      bottom: -0px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      z-index: 10;
      &-item {
        width: 30px;
        height: 4px;
        background-color: #C5CDD6;
        cursor: pointer;
        margin-right: 10px;
        transition: all .1s;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background-color: #535358;
        }
      }
    }
  }
  &-viewport {
    width: 100%;
    overflow: hidden;
  }
  &-list {
    display: flex;
    width: 9999%;
    transition: all 0.3s;
    &-item {
      padding: 35px;
      img {
        width: 1200px;
        height: 454px;
        border-radius: 36px;
        box-shadow: 0px 8px 35px 0px rgba(216,221,240,0.59);
      }
    }
  }
}
</style>

