<template>
  <div class="classroom">
    <h2 class="classroom-header">村居云课堂</h2>
    <ul class="classroom-list">
      <li :key="index" v-for="item,index in planlist" class="classroom-list-item">
        <a target="_blank" :href="item.url">
          <!-- <img src="@/assets/img/icon6.png" alt=""> -->
          <img :src="item.cdnurl" alt="">
          <!-- <div class="name">助理社工师全真套卷及答案详解</div> -->
          <div class="name">{{item.groupname}}</div>
        </a>
      </li>
    <!--  <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon7.png" alt="">
          <div class="name">社区工作技巧</div>
        </a>
      </li>
       <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon8.png" alt="">
          <div class="name">社会工作助力乡村治理</div>
        </a>
      </li>
      <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon9.png" alt="">
          <div class="name">每日一练：社工师考试单元练习</div>
        </a>
      </li>
      <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon10.png" alt="">
          <div class="name">社工师全真套卷及答案详解</div>
        </a>
      </li>
      <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon14.png" alt="">
          <div class="name">社会工作三大方法在社区的运用</div>
        </a>
      </li>
      <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon12.png" alt="">
          <div class="name">社区活动设计</div>
        </a>
      </li>
      <li class="classroom-list-item">
        <a href="">
          <img src="@/assets/img/icon13.png" alt="">
          <div class="name">社区调研</div>
        </a>
      </li> -->
    </ul>
    <img src="@/assets/img/banner2.png" alt="" class="classroom-banner">
  </div>
</template>
<script>
export default {
  name: "classroom",
  data() {
    return {
      planlist:[
        // {
        // groupname:'2023年初级社工师基础班-全科（预售中）',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=89eeb452156427d979dac43da9b3feb7',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/7/21/2023SGKQFDJCB.png'
        // },
        // {
        // groupname:'2023年初级社工师提升班（预售中）',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=36eebc7a3c57122953b6455213623190',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/7/21/2023SGKQFDTSB.png'
        // },
        // {
        // groupname:'2023年初级社工师冲刺班（预售中）',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=fd6f20712e471f35df250950731634a2',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/7/21/2023SGKQFDCCB.png'
        // },
        // {
        // groupname:'助理社工师全真套卷及答案详解',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=f5e1a37dbebdebfc9b2c4f2a0544e393',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2021/12/6/ZLSGSQZTJ2015-2021.jpg'
        // },
        // {
        // groupname:'2023年中级社工师精讲班-全科',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=0a05e1b10c2af8eeb49bc6323bbdc77d',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/9/29/00ZJSGJJBQK1.png'
        // },
        // {
        // groupname:'2023年中级社工师无忧班',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=c5d8f4d91aa424cfa6140240683cef82',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/9/28/00ZJSGWYBFM1.png'
        // },
        // {
        // groupname:'社工师考试大纲（中级）',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=003a0afe0e8056e2c77af737a893e804',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/1/19/20220119SGS001.png'
        // },
        // {
        // groupname:'社工师全真套卷及答案详解',
        // url:'https://cunjutong.yxybb.com/CCSWStudy/LEAP/STUDYWeb/WEB/html/courseDetails.html?courseid=8964e3e123793f0b0d10a1d6394d095f',
        // cdnurl:'https://download.yxybb.com/YXYBB/BBTH5/images/img/2021/12/6/SGSQZTJ2015-2021.jpg'
        // },
        
      ],
      shortname:'CJT_GW_HOME',
      areaid:null
    };
  },
  methods: {
    studyWeb_searchCourseClassify(pageNum, pagesize) {
      var _vm = this
      var params = {
        shortname: this.shortname ? this.shortname : null,
        areaid: this.areaid ? this.areaid : null,
        pageNum,
        pagesize,
      }
      BB.request('studyWeb_searchCourseClassify', params).then(function (data) {
        // console.log('studyWeb_searchCourseClassify', data)
        if (data.resultstate == 1 && data.result) {
          _vm.planlist = data.result.result
          // _vm.pageNum = data.result.pageNum
          // _vm.total = data.result.count
        } else {
          _vm.planlist = []
          // _vm.total = 0
        }
      })
    },
  },
  created(){
    this.studyWeb_searchCourseClassify(1,8);
  }
};
</script>
<style lang="less" scoped>
.classroom {
  &-header {
    font-size: 36px;
    color: #000;
    font-weight: 600;
    margin-top: 137px;
    margin-bottom: 62px;
    position: relative;
    text-align: center;
    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translate(-50%);
      width: 32px;
      height: 4px;
      border-radius: 2px;
      background-color: #26973e;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    &-item {
      margin-bottom: 40px;
      a {
        display: block;
        width: 270px;
        border-radius: 24px;
        background-color: #fff;
        transition: all .2s;
        box-shadow: 0px 8px 35px 0px rgba(216, 221, 240, 0.59);
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0px 8px 50px 0px rgba(216, 221, 240, 1);
        }
        img {
          width: 100%;
          height: 152px;
        }
        .name {
          width: 100%;
          height: 72px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #000;
          font-weight: 600;
          padding: 0 8px;
          box-sizing: border-box;
        }
      }
    }
  }
  &-banner {
    width: 1200px;
    margin-top: 65px;
    margin-bottom: 60px;
  }
}
</style>

