<template>
  <div class="banner">
   <img src="@/assets/img/banner.png" alt="" class="bg">
      <div class="banner-content">
        <div class="left">
          <h2>乡村振兴·关键在人</h2>
          <p>村居通，通村居，居通村，村通居</p>
          <img src="@/assets/img/code.png" alt="">
        </div>
        <div class="right">
          <img src="@/assets/img/banner-a.png" alt="">
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "Banner"
};
</script>
<style lang="less">
.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &-content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    padding-left: 17px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding-top: 147px;
    h2 {
      font-size: 50px;
      color: #000;
      line-height: 1;
      margin-bottom: 15px;
      font-weight: 600;
    }
    p {
      font-size: 24px;
      color: #425f58;
      line-height: 1;
      margin-bottom: 26px;
    }
    img {
      width: 208px;
      height: 236px;
    }
  }
  .right {
    width: 488px;
    padding: 38px 0 56px 0;
    padding-right: 85px;
    img {
      width: 100%;
    }
  }
}
</style>

