<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{

    }
  },
  beforeMount() {
    //Vue对象挂载到window下
    window.Vue = this;
  }
}
</script>
<style lang="less">
@import '@/assets/style/reset.less';
</style>
