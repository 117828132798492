<template>
  <div class="home">
    <Header id="2"></Header>
    <div class="pc-teacher-details">
      <div class="pc-teacher-details-header">
        <img :src="teacher.photocdnurl" alt="" class="prefix" />
        <div class="suffix">
          <p>{{ teacher.name }}</p>
          <span>{{ teacher.title }}</span>
        </div>
      </div>
      <div class="pc-teacher-details-content">
        <h2 class="title">个人简介</h2>
        <div v-html="teacher.content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  <script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AllTeachers from "@/assets/js/teachers.js";
export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      teachers: [...AllTeachers.shgz, ...AllTeachers.xlx],
      teacher: {},
    };
  },
  created() {
    // console.log(this.teachers);
    if (this.$route && this.$route.query && this.$route.query.id) {
      // console.log(this.$route.query.id);
      // const teacher = this.teachers.find(item=> item.id==this.$route.query.id)
      // if(teacher){
      //     this.teacher = teacher
      // }
      this.$utils.request(
        "getTeacherInfo",
        {
          bean: { id: this.$route.query.id },
        },
        (res) => {
          if (res) {
            if(res.description){
              let contents = res.description.split('\n');
              // console.log('contents',contents)
              if(contents && contents.length>0){
                res.content = '';
                for(let i=0;i<contents.length;i++){
                  res.content += '<p>'+contents[i]+'</p>';
                }
                
              }
            }
            this.teacher = res;
          }
        }
      );
    }
  },
};
</script>
  <style lang="less">
.pc-teacher-details {
  width: 100%;
  background-color: #f7f8fa;
  padding-top: 20px;
  margin-top: 80px;
}
.pc-teacher-details-header {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 1240px;
  box-sizing: border-box;
  height: 156px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 36px;
}
.pc-teacher-details-header .prefix {
  border-radius: 50%;
  width: 96px;
  height: 96px;
}
.pc-teacher-details-header .suffix {
  flex: 1;
  padding-left: 29px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.pc-teacher-details-header .suffix p {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}
.pc-teacher-details-header .suffix span {
  font-size: 14px;
  color: #8b8a8a;
}
.pc-teacher-details-content {
  width: 1240px;
  margin: 0 auto;
  padding: 43px 46px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  min-height: 696px;
}
.pc-teacher-details-content .title {
  font-size: 20px;
  font-weight: 600;
  padding-left: 16px;
  color: #000;
  position: relative;
  margin-bottom: 32px;
}
.pc-teacher-details-content .title::after {
  position: absolute;
  display: block;
  content: "";
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 16px;
  background-color: #5d92ff;
}
.pc-teacher-details-content p {
  font-size: 14px;
  color: #434343;
  line-height: 1.5;
  max-width: 787px;
  margin-bottom: 12px;
}
.pc-teacher-details-content .division {
  margin-bottom: 30px;
}
</style>
  
  