const shgz= [{
    id:'1',
    "name":"邓富友",
    "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/dfy.png",
    "tx":"高级社会师",
    "jj":"高级社会工作师，四川省青联委员、City&Guilds认证国际培训师、设计思维（DT）认证引导师...",
    "content":"<p>高级社会工作师，四川省青联委员、City&Guilds认证国际培训师、设计思维（DT）认证引导师。成都市民政局社会工作专家、四川省民政厅社会组织孵化培育专家、团中央中国青年创业导师，曾负责社创孵化器在北京、上海、南京、深圳等全国30余个城市的团队和业务管理工作，为超过3000家社会组织/社会企业/政府/社区提供过孵化、咨询和培训服务。拥有10年以上社会组织和社会工作服务项目的运营管理经验，在党建引领社区发展治理、社会组织培育、社会工作、社会企业、公益项目设计、项目管理与项目评估、志愿服务管理等方面有丰富的实务经验。并担任成都、南京、常州等多地政府党建引领社会组织、社会工作与社区发展治理特聘专家顾问。</p>"
},
{
    id:'5',
    "name":"邓拥军",
    "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/dyj.png",
    "tx":"社会工作硕士",
    "jj":"西南石油大学法学院，讲师，毕业于香港理工大学社会工作硕士。主讲《社区社...",
    "content":"<p>西南石油大学法学院，讲师，毕业于香港理工大学社会工作硕士。主讲《社区社会工作》、《个案社会工作》、《医务社会工作》、《儿童青少年社会工作》等社会工作专业课程。在社会工作实务方面，主要在社区社会工作、学校社会工作、医务社会工作等领域积累了多年的督导、项目评估等经验。社会工作培训方面，从事多年社会工作职业水平考试考前培训和成都市社会工作者继续教育培训，积累了多年培训经验。在社会工作研究方面，参与承担了多项国家社科基金课题、教育部课题、民政部课题及省级教改课题，参与编写了多本专著与教材等。</p>"
},
{
    id:'2',
    "name":"齐华栋",
    "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/qhd.png",
    "tx":"高级社会师",
    "jj":"男，中共党员，四川农业大学社工系老师，四川省民政厅社会工作人才专家库专家，四川省社会工...",
    "content":"<p>男，中共党员，四川农业大学社工系老师，四川省民政厅社会工作人才专家库专家，四川省社会工作人才培训基地培训专家、四川省灾害社会工作服务队督导专家。毕业于香港理工大学社会工作硕士，中山大学社会学博士。</p>"+
    "<p>主要从事灾后社区重建、行动研究、儿童生命教育等研究，主要承担《个案工作》《社会工作实务》等课程的教学。主研国家社科基金1项，教育部课题2项，参编3部著作，发表文章5篇，曾获四川省教学成果奖二等奖、四川农业大学本科课堂教学质量一等奖。</p>"

},
{
    id:'4',
    "name":"王献蜜",
    "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/wxm.png",
    "tx":"副教授",
    "jj":"社会工作师，硕士生导师，中华女子学院社会工作系主任，清华大学北京协和医学院博士，北京大...",
    "content":"<p>社会工作师，硕士生导师，中华女子学院社会工作系主任，清华大学北京协和医学院博士，北京大学访问学者，加拿大曼尼托巴大学访问学者，中国社会工作教育协会医务社会工作专委会常务理事，中国社会工作教育协会残障与康复专委会常务理事，中国社会工作联合会医务社工专委会常务理事，中国医院协会医院社会工作暨志愿服务工作委员会委员，北京医院协会医务社会工作专委会特邀委员，北京枫林社会工作发展中心理事，北京芳华社会工作服务中心监事。2020年度全国十大医务社工，2020年度中央和国家机关三八红旗手，2021年度北京市课程思政教学名师。发表论文20余篇，出版专著3部，主持并参与科研项目40余项。担任北京市家庭综合服务项目、优才计划项目、医务社工试点项目、阳光计划等项目督导。</p>"
},
{
    id:'3',
    "name":"张银娟",
    "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/zyj.png",
    "tx":"社会工作硕士",
    "jj":"中南民族大学社会工作专业硕士，2015年以来先后负责流动儿童、青少年、社区养老、司法保...",
    "content":"<p>中南民族大学社会工作专业硕士，2015年以来先后负责流动儿童、青少年、社区养老、司法保护等不同领域的服务项目。同时具备社区工作者、“三农”等科目的丰富授课经验。</p>"
}]
const xlx = [
    
    {
        id:'14',
        "name":"陈顺森",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/css.png",
        "tx":"心理学专家",
        "jj":"中国心理学会眼动心理研究专业委员会委员，中国心理学会心理学质性研究专业委员...",
        "content":"<p>中国心理学会眼动心理研究专业委员会委员，中国心理学会心理学质性研究专业委员会委员，闽南师范大学教育科学学院院长、教授、硕士生导师。</p>"
        +"<p>研究方向：认知发展与教育，研究领域：箱庭疗法、自闭症、考试焦虑，现有国家认证专利4项。</p>"
    },
    {
        id:'24',
        "name":"樊富珉",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/ffm.png",
        "tx":"心理学教授",
        "jj":"北京师范大学心理学部临床与咨询心理学院院长，原清华大学心理学教授...",
        "content":"<p>北京师范大学心理学部临床与咨询心理学院院长，原清华大学心理学教授，博导，教育部普通高校学生心理健康教育专家指导委员会委员，国家卫生健康委员会精神医学与心理健康专家委员会委员，中国心理学会危机干预工作委员会副主任委员，社会科学学院积极心理学研究中心主任，曾获中国心理卫生协会大学生心理咨询专业委员会终身成就奖、中国心理学会学科建设成就奖、健康中国十大年度人物。</p>"
        +"<p>出版专著20余册，学术论文80多篇。参加非典心理援助，汶川地震灾后心理援助，富士康危机事件干预等。</p>"
    },
    {
        id:'15',
        "name":"方玮联",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/fwl.png",
        "tx":"心理学专家",
        "jj":"中国生命关怀协会静观专业委员会执行主任委员，中国生命关怀协会婚姻与家庭专业委员...",
        "content":"<p>中国生命关怀协会静观专业委员会执行主任委员，中国生命关怀协会婚姻与家庭专业委员会委员，中国社会工作联合会心理健康工作委员会委员静观心理学部副主任委员，英国牛津大学静观中心顾问 （OMC Advisor），静观减压课程（MBSR）认证教师、督导师及师资培训师（通过美国麻省大学医学院静观中心UMASS CFM与美国布朗大学静观中心 MC@B 双重认证），静观认知疗法（MBCT）认证教师（通过英国牛津大学静观中心师资教学能力评估）及督导师，盖亚之树静观中心创会导师，英国华威大学商学院硕士。</p>"
    },
    {
        id:'20',
        "name":"高德明",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/gdm.png",
        "tx":"心理学专家",
        "jj":"中国社会工作联合会心理健康工作委员会副主任，国内首批TA交互心理学专家...",
        "content":"<p>中国社会工作联合会心理健康工作委员会副主任，国内首批TA交互心理学专家，国内Solution-Focused Coaching、语言微观分析应用专家，SFC培训师及督导师。</p>"
    },
    {
        id:'7',
        "name":"姜华",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/jh.png",
        "tx":"中级社工师",
        "jj":"民政部培训中心、北京社会管理职业学院教师。中国社会工作教育协会医务社会工作委员会委员...",
        "content":"<p>民政部培训中心、北京社会管理职业学院教师，中国社会工作教育协会医务社会工作委员会委员，中国社会工作联合会残障康复工作委员会理事。德国大学博士研究生，研究方向：两性社会学、医疗社会工作、社区社会工作、灾害救灾员培训。发表专业论文7篇，个人论著2本。曾就职于德国奥尔登堡大学，德国教会医院。多次参与欧洲和德国境内各种科研和合作项目，并数次参加各种国际交流会议。</p>"
    },
    {
        id:'26',
        "name":"刘松怀",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lhs.png",
        "tx":"心理学专家",
        "jj":"中国康复研究中心心理科主任、研究员，首都医科大学康复医学院心理教研室主任...",
        "content":"<p>中国康复研究中心心理科主任、研究员，首都医科大学康复医学院心理教研室主任，北京心理卫生协会副理事长，北京市社会心理工作联合会副会长，中国心理卫生协会理事，中国心理卫生协会特群专业委员会主任委员，中国残疾人康复协会心理康复专业委员会副主任委员，残疾人事业发展研究会心理健康专业委员会秘书长，中国心理学会注册督导师（D-19-036），中国心理卫生协会注册督导师（XXD-2020-055）。</p>"
    },
    {
        id:'21',
        "name":"李莉",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lili.png",
        "tx":"心理学专家",
        "jj":"中国生命关怀协会婚姻与家庭专业委员会委员，中国社会工作联合会心理健康工作...",
        "content":"<p>中国生命关怀协会婚姻与家庭专业委员会委员，中国社会工作联合会心理健康工作委员会理事，中国教育学会学校文化研究分会理事，山东一泓健康研究中心院长，山东省心理卫生协会常务理事，济南市心理咨询师协会常务副会长，人偶心游创始人，《社会工作者心理学基础读本》编委，《婚恋情感指导手册》编委。</p>"
    },
    {
        id:'13',
        "name":"刘露曦",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/llx.png",
        "tx":"心理咨询师",
        "jj":"国际存在-人本心理学院（IIEHP）COO、中方教员、心理咨询师，美国AGPA团体心理治...",
        "content":"<p>国际存在-人本心理学院（IIEHP）COO、中方教员、心理咨询师，美国AGPA团体心理治疗协会成员，APA美国心理学会人本分会成员，管理学及心理咨询与治疗双重背景，国内首批存在—人本心理咨询师认证以及美国亚隆学院的团体咨询师认证，重庆市女专家联谊会理事。</p>"
    },
    {
        id:'16',
        "name":"李明",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lm.png",
        "tx":"心理系副教授",
        "jj":"博士，耶鲁大学访问学者，北京林业大学心理系副教授，硕士生导师。北京中医药大学国...",
        "content":"<p>博士，耶鲁大学访问学者，北京林业大学心理系副教授，硕士生导师。北京中医药大学国学院客座教授，中国心理学会注册督导师，婚姻与家庭专业委员会委员，中国社会工作联合会心理健康工作委员会常务理事，国际EAP协会中国分会理事。</p>"
        +"<p>中国心理卫生协会心理咨询师专业委员会委员，青年工作专业委员会委员，文化与治疗学组委员，中国生命关怀协会婚姻与家庭专业委员会常务委员，北京心理卫生协会心理治疗与咨询委员会常务委员。</p>"
        +"<p>发表《叙事心理治疗导论》、《艺术心理治疗》《叙事心理治疗》、《成人之美：明说叙事》等专著，《叙事疗法实践地图》、《精神分析与中国人的心理世界》、《性、生态、灵性》等译著，在国内外重要学术期刊发表论文数十篇。</p>"
    },
    {
        id:'25',
        "name":"刘晓萍",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lxp.png",
        "tx":"心理学专家",
        "jj":"中国心理学会发展心理学专业委员会委员，中国社会工作联合会心理健康工作...",
        "content":"<p>中国心理学会发展心理学专业委员会委员，中国社会工作联合会心理健康工作委员会常务理事。</p>"
    },
    {
        id:'18',
        "name":"李艳",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lyan.png",
        "tx":"心理学博士",
        "jj":"美国杜克大学（Duke University）心理咨询中心执照心理学家...",
        "content":"<p>美国杜克大学（Duke University）心理咨询中心执照心理学家。</p>"
        +"<p>心理咨询专业硕士、博士，博士后临床导师，ActValue国际心理咨询与顾问公司创办者。</p>"
    },
    {
        id:'8',
        "name":"李勇",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/lyong.png",
        "tx":"心理学硕士",
        "jj":"国家二级心理咨询师、培训师，中国心理卫生协会会员，中国科学院心理研究所首批督导心...",
        "content":"<p>心理学硕士，国家二级心理咨询师、培训师。中国心理卫生协会会员，中国科学院心理研究所首批督导心理教练，中国管理科学研究院注册心理教练，中科院心理援助专家创新团队成员，河北前程管理咨询有限公司董事长，沙盘游戏（箱庭疗法）培训导师，著名心理学专家、亲子教育专家。</p>"
    },
    {
        id:'6',
        "name":"吴宝沛",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/wbp.png",
        "tx":"心理学系副教授",
        "jj":"北京师范大学心理学院硕士，香港中文大学教育心理学系博士...",
        "content":"<p>北京师范大学心理学院硕士，香港中文大学教育心理学系博士，北京林业大学心理学系副教授，硕士生导师，研究方向为进化心理学。出版有《爱人、情人和怪人》（2015年）、《臭皮囊：我们为何容易生病》（2016年）、《深渊：抑郁流行的进化根源》（罗锐合译，2017年）。</p>"
    },
    {
        id:'11',
        "name":"岳晓东",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/yxd.png",
        "tx":"心理学专家",
        "jj":"首都师范大学心理学院特聘教授（Distinguished Professor）、博导，中国心理学会注册督导...",
        "content":"<p>首都师范大学心理学院特聘教授（Distinguished Professor）、博导，中国心理学会注册督导师（D-21-192），香港心理学会会士（Fellow），香港心理学会注册心理学家（辅导心理学）、香港心理学会辅导心理学分会首任会长（2006-2009）。</p>"
        +"<p>1985年赴美国塔夫兹（Tufts University）大学攻读教育学硕士学位，1987年毕业并赴美国哈佛大学（Harvard University）教育研究院攻读心理学博士学位，1993年毕业后赴香港工作，先后在香港中文大学（1993-1996）和香港城市大学（1997-2020）任教。</p>"
        +"<p>岳晓东教授在个人心理健康、创新心理学、幽默心理学和青少年偶像崇拜等方面做出了大量的研究，在国外的SSCI学术刊物上发表学术论文80余篇，在内地和香港的核心学术刊物上发表文章80余篇，并出版英文学术专著4部，中文学术专著8部。此外，还著有《登天的感觉》《写好孩子的人生脚本》等心理学科普读物，深受读者喜爱。</p>"
    },
    {
        id:'17',
        "name":"张驰",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/zc.png",
        "tx":"心理学专家",
        "jj":"北京交通大学心理素质教育中心副主任，2008年亚隆团体治疗模式培训首批学员...",
        "content":"<p>北京交通大学心理素质教育中心副主任，2008年亚隆团体治疗模式培训首批学员，中国心理卫生协会特殊职业专业委员会委员，中国心理卫生协会青年工作委员会委员，中国心理卫生协会科普专家，北京心理卫生协会心理咨询与治疗专委会常委。</p>"
    },
    {
        id:'19',
        "name":"庄明科",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/zmk.png",
        "tx":"副教授",
        "jj":"北京大学心理健康教育与咨询中心副主任、副教授，北京大学心理与认知科学学院...",
        "content":"<p>北京大学心理健康教育与咨询中心副主任、副教授，北京大学心理与认知科学学院心理学博士，中国心理学会注册心理师（X-21-056），教育部全国高校就业创业指导教师培训特聘专家，九三学社中央医药卫生专门委员会委员。</p>"
    },
    {
        id:'10',
        "name":"庄悦明",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/zym.png",
        "tx":"法学硕士",
        "jj":"中国政法大学民商经济法专业法学硕士研究生，中华全国律师协会会员，北京市律师协会...",
        "content":"<p>中国政法大学民商经济法专业法学硕士研究生，中华全国律师协会会员，北京市律师协会PPP专业委员会委员，山西省财政厅、天津市财政局PPP法律专家。</p>"
    },
    {
        id:'12',
        "name":"张志斌",
        "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/zzb.png",
        "tx":"心理咨询师",
        "jj":"国际存在-人本心理学院（IIEHP）副院长、CEO、中方教员、讲师，中国社工联合会心理健...",
        "content":"<p>国际存在-人本心理学院（IIEHP）副院长、CEO、中方教员、讲师，中国社工联合会心理健康工作委员会催眠学部委员，北京心理卫生协会心理咨询与治疗专业委员会委员，国家二级心理咨询师、中科院心理所心理健康指导师。</p>"
    },
    // {
    //     id:'9',
    //     "name":"唐忠新",
    //     "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/tzx.png",
    //     "tx":"社会学系教授",
    //     "jj":"南开大学社会学系研究员、教授，中国农村社会学研究会理事、民政部社区建设专家顾问组...",
    //     "content":"<p>南开大学社会学系研究员、教授，中国农村社会学研究会理事、民政部社区建设专家顾问组成员、中国社会工作联合会社区工作委员会副主任、中国社会学会理事、天津市社会学学会秘书长。</p>"
    //     +"<p>先后主持承担国家和省部级以及地方政府委托研究课题多项，著有《现代城市社区建设概论》、《社区志愿服务理论与实务》、《迈向和谐社会的社区服务》等图书。</p>"
    // },
    // {
    //     id:'22',
    //     "name":"闫洪丰",
    //     "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/yhf.png",
    //     "tx":"心理学专家",
    //     "jj":"中国残联直属机关工会副主席兼团委书记，中国心理学会心理学服务机构工作委员会副...",
    //     "content":"<p>中国残联直属机关工会副主席兼团委书记，中国心理学会心理学服务机构工作委员会副主任委员，中央国家机关第四届青联委员、兼任中央国家机关职工心理咨询中心副主任，北京大学心理教育和咨询中心特聘副主任兼心理专家。</p>"
    // },
    // {
    //     id:'23',
    //     "name":"潘烈青",
    //     "photo": "https://download.yxybb.com/YXYBB/BBTH5/images/img/2022/10/17/plq.png",
    //     "tx":"会长",
    //     "jj":"上海市街镇工作协会会长，中国社会工作联合会社区工作委员会副...",
    //     "content":"<p>上海市街镇工作协会会长，中国社会工作联合会社区工作委员会副主任委员，上海市民政局基层政权和社区建设处原处长。</p>"
    // },



]
const all_teachers = {
    shgz,
    xlx
}
export default all_teachers;