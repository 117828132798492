<template>
  <div class="footer">
    <div class="footer-content">
      <div class="left">
        <div class="label" style="width:180px;">
          <h2 class="title">相关链接</h2>
          <ul>
            <li><a target="_blank" href="https://shgz.mca.gov.cn">中国社会工作网</a></li>
            <li><a target="_blank" href="https://chinavolunteer.mca.gov.cn">中国志愿服务网</a></li>
            <li><a target="_blank" href="https://www.longrise.cn">永兴元科技官网</a></li>
            <li><a target="_blank" href="https://yiwan.org.cn">一湾社会工作发展研究院</a></li>
          </ul>
        </div>
        <div class="label">
          <h2 class="title">联系我们</h2>
          <ul>
            <li><a href="">立即咨询</a></li>
          </ul>
        </div>
      </div>
      <ul class="center"> 
        <li>
          <img src="@/assets/img/code2.png" alt="">
          <p>村居通企业微信活码</p>
        </li>
        <li>
          <img src="@/assets/img/code3.png" alt="">
          <p>村居通微信公众号</p>
        </li>
        <li>
          <img src="@/assets/img/code4.png" alt="">
          <p>村居易购小程序码</p>
        </li>
        <li>
          <img src="@/assets/img/code5.png" alt="">
          <p>村居云课堂H5访问码</p>
        </li>
      </ul>
      <div class="right">
        <img src="@/assets/img/icon15.png" alt="">
        <p class="num">027-59867909转2</p>
        <span>周一至周五9：00-18：00</span>
        <span>cunjutong@163.com</span>
      </div>
    </div>
    <div class="footer-copyright">
      <p>
        Copyright 2022-2024村居通信息服务（深圳）有限公司版权所有 
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022125819号</a>
        <!-- <img src="@/assets/img/icon16.png" alt="">
        工商网监电子标识
        <img src="@/assets/img/icon17.png" alt="">
        粤ICP备2022125819号 -->
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.footer {
  background-color: #343635;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-content {
    width: 1200px;
    display: flex;
    padding-top: 50px;
    padding-bottom: 33px;
    .left {
      padding-top: 13px;
      display: flex;
      .label {
        width: 150px;
        .title {
          font-size: 16px;
          color: #fff;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 28px;
        }
        ul {
          li {
            margin-bottom: 15px;
            a {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
    .center {
      padding-top: 10px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      li {
        img {
          width: 126px;
          height: 126px;
        }
        p {
          font-size: 14px;
          color: #FFFFFF;
          margin-top: 25px;
          text-align: center;
        }
      }
    }
    .right {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      img {
        width: 169px;
        height: 63px;
      }
      .num {
        font-size: 30px;
        color: rgba(255, 255, 255, .5);
        margin-top: 34px;
        font-weight: 600;
        line-height: 1;
      }
      span {
        display: block;
        margin-top: 14px;
        font-size: 14px;
        color: rgba(255, 255, 255, .5);
        line-height: 1;
      }
    }
  }
  &-copyright {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, .19);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    p {
      font-size: 14px;
      color: rgba(255, 255, 255, .5);
      display: flex;
      align-items: center;
      img {
        width: 41px;
        height: 46px;
        margin: 0 5px 0 32px;
      }
    }
  }
}
</style>

