import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/css/reset.css';
import axios from "axios";
import utils from "./assets/js/utils.js";

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
//工具类
Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
