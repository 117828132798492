<template>
  <ul class="icons">
    <li class="icons-item">
      <a href="javascript:;">
        <img src="@/assets/img/icon1.png" alt="">
        <h2>信息直报</h2>
        <p>不忘初心，助力信息惠 政，推动信息惠民</p>
      </a>
    </li>
    <li class="icons-item">
      <a href="javascript:;">
        <img src="@/assets/img/icon2.png" alt="">
        <h2>公益课堂</h2>
        <p>乡村振兴重在人才培养 精品好课助您成长</p>
      </a>
    </li>
    <li class="icons-item">
      <a href="javascript:;">
        <img src="@/assets/img/icon3.png" alt="">
        <h2>村居之声</h2>
        <p>无论身在何处，小通与你常相伴</p>
      </a>
    </li>
    <li class="icons-item">
      <a href="javascript:;">
        <img src="@/assets/img/icon4.png" alt="">
        <h2>村居调研</h2>
        <p>一键直达，认清真心，让每个心愿都有成真的可能</p>
      </a>
    </li>
    <li class="icons-item">
      <a href="javascript:;">
        <img src="@/assets/img/icon5.png" alt="">
        <h2>村居资讯</h2>
        <p>随时随地了解乡村振兴 人才培养、信息惠民等 相关的新闻报道</p>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "Header"
};
</script>
<style lang="less">
.icons {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  width: 1200px;
  box-sizing: border-box;
  &-item {
    transition: all .2s;
    &:hover {
      transform: translateY(-10px);
    }
    a {
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 140px;
      height: 140px;
    }
    h2 {
      margin: 22px 0 18px 0;
      color: #000;
      font-weight: 600;
      font-size: 24px;
    }
    p {
      text-align: center;
      line-height: 1.5em;
      font-size: 16px;
      color: #828e9e;
    }
  }
}
</style>

