<template>
  <div class="home">
    <HomeHeader id="1"></HomeHeader>
    <HomeBanner></HomeBanner>
    <HomeIcons></HomeIcons>
    <HomeSwiper></HomeSwiper>
    <HomeClassroom></HomeClassroom>
    <Footer></Footer>
  </div>
</template>
<script>
import HomeHeader from '@/components/Header.vue';
import HomeBanner from '@/components/Banner.vue';
import HomeIcons from '@/components/Icons.vue';
import HomeSwiper from '@/components/Swiper.vue';
import HomeClassroom from '@/components/Classroom.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'HomeView',
  components: {
    HomeHeader,
    HomeBanner,
    HomeIcons,
    HomeSwiper,
    HomeClassroom,
    Footer
  }
}
</script>
<style lang="less">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

