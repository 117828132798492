/*********************Vue工具类*******************/

function getPath() {
  // var path = "http://192.168.0.122:8080/ECSWX";
  // var path = "http://192.168.0.61:8050/CJT"; //测试

  return location.pathname != "/"
    ? location.origin + "/" + location.pathname.split("/")[1]
    : path;
}
//定义项目常用属性
const path = {
  //上传路径
  basePath: getPath(),
  //下载路径
  downPath: getPath() + "/LEAP/Download",
  //网页地址
  baseUrl: location.origin + location.pathname
};

//根据链接获取参数
const getUrl = function(name) {
  console.log("准备获取url参数：",name);
  var value = null;
  var param = location.search;
  if (param == "" && location.hash != "") {
    param = location.hash;
  }
  if (param.indexOf("?") != -1) {
    param = param.split("?")[1];
    var arr = null;
    if (param.indexOf("&") != -1) {
      arr = param.split("&");
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(name) != -1) {
          value = arr[i].replace(name + "=", "");
        }
      }
    } else if (param.indexOf(name) != -1) {
      value = param.replace(name + "=", "");
    }
  }
  console.log("获取url参数：",name,value);
  return value;
};

//发送请求
const request = function(method, bean, callback) {
  if (typeof leapclient == "object") {
    //平台Net.js封装请求
    var res = leapclient.request(method, bean, null, null, "web", "web");
    // console.log(res);
    callback(res);
  } else {
    Vue.$axios
      .post("/LCUC" + "/restservices/web/" + method + "/query", bean)
      .then(res => {
        if (res && res.status == 200) {
          callback(res.data);
        } else {
          callback(null);
          console.log("请求失败！");
        }
      }).catch(function (error) {
        callback(null);
        console.log(error);
      });
  }
};

//发送请求(不加密)
const request2 = function(method, bean, callback) {
  Vue.$axios
    .post("/LCUC" + "/restservices/web/" + method + "/query", bean)
    .then(res => {
      if (res && res.status == 200) {
        // callback(res.data);
      } else {
        callback(null);
        console.log("请求失败！");
      }
    }).catch(function (error) {
    callback(null);
    console.log(error);
  });
};

//获取代码表
const getCodeValues = function(data, callback) {
  if (data) {
    var param = { codetype: data };
    Vue.$utils.request("getCodeValues", param, function(res) {
      if (res && res.code == 200) {
        if (callback) {
          callback(res.data);
        }
      }
    });
  }
};

//自定义请求
var requestUpload = function(url, form, obj, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.onload = function(e) {
    if (typeof obj == "object") {
      if (this.status == 200) {
        obj.success(this.response);
      } else {
        obj.error(this.response);
      }
    }
  };
  xhr.onerror = function(e) {
    console.log(e);
    obj.error(this);
  };

  if (typeof callback == "function") {
    //返回文件上传进度
    xhr.upload.onprogress = function(e) {
      if (e.lengthComputable) {
        var pro = Math.round((e.loaded / e.total) * 100);
        if (pro) {
          console.log(form.get('file'))
          callback(pro);
        }
      }
    };
  }
  xhr.send(form);
};
var setFail = function(f) {
  if (f) {
    f.status = "failed";
    f.message = "上传失败";
  }
};
// 图片上传
const uploadImg = function(files) {
  files.status = "uploading";
  files.message = "上传中...";
  try {
    var imgs = files.file;
    var formData = new FormData(); //新建一个formData来储存需要传递的信息
    formData.append("file", imgs); //需要传递的字段image路径
    formData.append("dirname", "cjt");
    requestUpload(
      Vue.$utils.path.basePath + "/restservices/web/WXV6_uploadImage/query",
      formData,
      {
        success: function(res) {
          try {
            if (res) {
              res = JSON.parse(res);
              if (res.data != null) {
                var data = res.data;
                if (data != null) {
                  var file = JSON.parse(data.file);
                  if (file) {
                    //报存数据
                    files.data = file;
                    files.status = "done";
                    return;
                  }
                }
              } else if (res.message) {
                Vue.$layer.msg(res.message);
              } else {
                console.log(res);
              }
            }
          } catch (error) {
            setFail(files);
          }
          setFail(files);
        },
        error: function(res) {
          setFail(files);
        }
      }
    );
  } catch (e) {
    console.log("发送请求异常！" + e);
    setFail(files);
  }
};

// 文件上传
const uploadFile = function(files, callback) {
  files.status = "uploading";
  files.message = "上传中...";
  try {
    var imgs = files.file;
    var formData = new FormData(); //新建一个formData来储存需要传递的信息
    formData.append("file", imgs); //需要传递的字段image路径
    requestUpload(
        "/LCUC/restservices/web/sqgz_h5_fileUpload/query",
      formData,
      {
        success: function(res) {
          try {
            if (res) {
              res = JSON.parse(res);
              if (res.length) {
                var data = res[0];
                if (data) {
                  //报存数据
                  files.data = data;
                  files.status = "done";
                  return;
                }
              }
            }
          } catch (error) {
            setFail(files);
          }
          setFail(files);
        },
        error: function(res) {
          setFail(files);
        }
      },
      callback
    );
  } catch (e) {
    console.log("发送请求异常！" + e);
    setFail(files);
  }
};

// 文件上传
const uploadFile2 = function(files, callback) {
  files.status = "uploading";
  files.message = "上传中...";
  try {
    var imgs = files.file;
    var formData = new FormData(); //新建一个formData来储存需要传递的信息
    formData.append("file", imgs); //需要传递的字段image路径
    requestUpload(
        "/LCUC/restservices/web/sqgz_h5_fileUpload/query",
      formData,
      {
        success: function(res) {
          try {
            if (res) {
              res = JSON.parse(res);
              if (res.length) {
                var data = res[0];
                if (data) {
                  //报存数据
                  files.data = data;
                  files.status = "done";
                  return;
                }
              }
            }
          } catch (error) {
            setFail(files);
          }
          setFail(files);
        },
        error: function(res) {
          setFail(files);
        }
      },
      callback
    );
  } catch (e) {
    console.log("发送请求异常！" + e);
    setFail(files);
  }
};

//页面跳转
const toPage = function(page, data) {
  var url = null;
  if (page == "wdqy") {
    //我的权益-使用
    url = "/Web/html/myquanyi.html?" + data;
  } else if (page == "fybz") {
    //我的保障-投保
    url = "/Web/html/proddesc.html?1=1";
  }

  //信息填报 表单页面 (排除缓存情况）
  else if (page == "xxtb") {
    url = "/CJT/index.html?page=xxtb";
  }

  if (url) {
    //过滤微信客户端拦截
    if (location.href.indexOf("192.168.0") != -1) {
      window.location.href =
        "http://192.168.0.154:8080/ECSWX/LEAP" +
        //"http://113.57.175.210:5580/LCUCV6/LEAP" +
        url +
        "&timetmp=" +
        new Date().getTime();
    } else {
      window.location.href =
        location.origin +
        "/" +
        location.pathname.split("/")[1] +
        "/LEAP" +
        url +
        "&timetmp=" +
        new Date().getTime();
    }
  }
};

//获取微信openid
const getOpenid = function() {
  var openid = null;
  if (typeof weixin == "object") {
    openid = weixin.openid;
  }
  if (!openid) {
    openid = Storage.getStore("openid");
  }
  if (!openid) {
    Vue.$layer.msg("获取微信用户信息失败，请刷新重试");
  }
  return openid;
};

function dealUser(user, callback) {
  if (typeof callback == "function") {
    callback(user);
  }
}
//
const getVersion = function(callback) {
  request("cjt_gzh_version",null,callback);
};
//获取用户信息 (初始化加载时调用)
const getUserInfo = function(callback) {
  var key = "user";
  var user = null;
  if (Vue.user) {
    dealUser(Vue.user, callback);
    return;
  }
  var openid = Storage.getStore("openid");
  if (openid) {
    Vue.$utils.request("WXV6_sWxUserInfoByOpenid", { openid: openid }, function(
      res
    ) {
      console.log('WXV6_sWxUserInfoByOpenid',res);
      if (res.state == 1 && res.result) {
        var result = res.result;
          if (res.userinfo) {
            user = res.userinfo;
          } else {
            user = {};
          }
          user.openid = openid;
          user.subscribe = result.subscribe;
          user.nickname = result.nickname;
          user.headimgurl = result.headimgurl;
          Vue.user = user;
          Vue.reload();
          // dealUser(user, callback);
      }
    });
  }
};

//是否移动端 微信内置浏览器
const isWxBrower = function() {
  var ua = window.navigator.userAgent;
  if (ua.indexOf('MicroMessenger') > -1){
    return true;
  }
  return false;
};

//刷新页面显示路径
const refreshUrl = function(Vue, key, value, type) {
  var route = Vue.$route;
  if (route.params[key] != value) {
    var params = {};
    params[key] = value;
    if (type == "push") {
      Vue.$router.push({
        //添加浏览器记录
        name: route.name,
        params: params,
        query: route.query
      });
    } else {
      Vue.$router.replace({
        name: route.name,
        params: params,
        query: route.query
      });
    }
  }
};

function getDateString(date) {
  if (!date) {
    date = new Date();
  }
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    date.getFullYear() +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day
  );
}
const toDate = function(date) {
  return getDateString(date);
};

const getCardInfo = function(card, num) {
  if (num == 1) {
    //获取出生日期
    let birth = new Date(
      card.substring(6, 10),
      parseInt(card.substring(10, 12)) - 1,
      card.substring(12, 14)
    );
    return getDateString(birth);
  }
  if (num == 2) {
    //获取性别
    if (parseInt(card.substr(16, 1)) % 2 == 1) {
      return "0"; //男
    } else {
      return "1"; //女
    }
  }
  if (num == 3) {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - card.substring(6, 10) - 1;
    if (
      card.substring(10, 12) < month ||
      (card.substring(10, 12) == month && card.substring(12, 14) <= day)
    ) {
      age++;
    }
    return age;
  }
};

export default {
  path,
  getUrl,
  request,
  request2,
  getCodeValues,
  uploadImg,
  uploadFile,
  uploadFile2,
  toPage,
  getOpenid,
  isWxBrower,
  getUserInfo,
  refreshUrl,
  toDate,
  getCardInfo,
  getVersion,
  requestUpload
};
